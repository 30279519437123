import React from 'react'

const SuccessMessage = () => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  return (
  <div className="text-sm text-center text-gray-800">
    <h2 className="text-2xl font-bold text-black"> {language == 'en' ? 'Thank you!' : 'Grazie'}</h2>
      <p>
      {language == 'en' ? <>
        Your message was sent to us.
        <br />
        We’ll get back to you!
      </> : <>
        Il tuo messaggio ci è stato inviato..
        <br />
        Ti risponderemo presto!!
      </>}

      
    </p>
    <img
      className="w-32 h-32 mx-auto my-8 bg-gray-800 rounded-full"
      src="/profile.png"
      alt="Team member profile"
    />
    <p className="text-base md:text-lg">~ Team Soul Valley</p>
  </div>
)}

export default SuccessMessage
